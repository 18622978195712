import React from "react";
import { useStaticQuery, graphql } from "gatsby";

/* wordpress expresses dates using PHP's native date formatting.
*  this object has been extracted from https://www.php.net/manual/en/datetime.format.php
*/
const leadingZeros = (str) => {
  str = (typeof str !== "string") ? str.toString() : str;
  return (str.length < 2) ? "0"+str:str;
};
const phpDateFormats = {
  /* day */
  "d":{
    "description":"Day of the month, 2 digits with leading zeros",
    "example":"01 to 31",
    "method": (date) => leadingZeros(date.getDate())
  },
  "D":{
    "description":"A textual representation of a day, three letters",
    "example":"Mon through Sun",
    "method": (date, locale) => date.toLocaleString(locale, { weekday: "short" }).slice(0,3)
  },
  "j":{
    "description":"Day of the month without leading zeros",
    "example":"1 to 31",
    "method": (date) => date.getDate().toString()
  },
  "l":{
    "description":"A full textual representation of the day of the week",
    "example":"Sunday through Saturday",
    "method": (date, locale) => date.toLocaleString(locale, { weekday: "long" })
  },
  "N":{
    "description":"ISO 8601 numeric representation of the day of the week",
    "example":"1 (for Monday) through 7 (for Sunday)",
    "method": (date) => {
      let dow = date.getDay();
      dow = (dow === 0) ? 7 : dow;
      return dow.toString();
    }
  },
  "S":{
    "description":"English ordinal suffix for the day of the month, 2 characters",
    "example":"st, nd, rd or th. Works well with j",
    "method": (date) => {
      let dom = date.getDate();
      if ([11,12,13].includes(dom)) {
        return "th";
      }
      dom = dom.toString();
      dom = dom[dom.length-1];
      switch (dom) {
      case "1":
        return "st";
      case "2":
        return "nd";
      case "3":
        return "rd";
      default:
        return "th";
      }
    }
  },
  "w":{
    "description":"Numeric representation of the day of the week",
    "example":"0 (for Sunday) through 6 (for Saturday)",
    "method": (date) => date.getDay().toString()
  },
  "z":{
    "description":"The day of the year (starting from 0)",
    "example":"0 through 365",
    "method": (date) => {
      //adapted from https://stackoverflow.com/a/50116028/8086209
      const dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
      const isLeapYear = (new Date(date.getFullYear(), 2, 0).getDate() === 29);
      const mn = date.getMonth();
      const dn = date.getDate();
      return dayCount[mn] + dn + ((mn > 1 && isLeapYear) ? 1:0) ;
    }
  },
  /* week */
  "W":{
    "description":"ISO 8601 week number of year, weeks starting on Monday",
    "example":"Example: 42 (the 42nd week in the year)",
    "method": (date) => {
      //adapted from https://stackoverflow.com/a/39502645/8086209
      const dateCopy = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      const nDay = (dateCopy.getDay() + 6) % 7;
      dateCopy.setDate(dateCopy.getDate() - nDay + 3);
      const n1stThursday = dateCopy.valueOf();
      dateCopy.setMonth(0, 1);
      if (dateCopy.getDay() !== 4) {
        dateCopy.setMonth(0, 1 + ((4 - dateCopy.getDay()) + 7) % 7);
      }
      return 1 + Math.ceil((n1stThursday - dateCopy) / 604800000);
    }
  },
  /* month */
  "F":{
    "description":"A full textual representation of a month, such as January or March",
    "example":"January through December",
    "method": (date, locale) => date.toLocaleString(locale, { month: "long" })
  },
  "m":{
    "description":"Numeric representation of a month, with leading zeros",
    "example":"01 through 12",
    "method": (date) => leadingZeros(date.getMonth()+1)
  },
  "M":{
    "description":"A short textual representation of a month, three letters",
    "example":"Jan through Dec",
    "method": (date, locale) => date.toLocaleString(locale, { month: "short" }).slice(0,3)
  },
  "n":{
    "description":"Numeric representation of a month, without leading zeros",
    "example":"1 through 12",
    "method": (date) => date.getMonth().toString()
  },
  "t":{
    "description":"Number of days in the given month",
    "example":"28 through 31",
    "method": (date) => new Date(date.getFullYear(), date.getMonth()+1, 0).getDate().toString()
    
  },
  /* year */
  "L":{
    "description":"Whether it's a leap year",
    "example":"1 if it is a leap year, 0 otherwise.",
    "method": (date) => new Date(date.getFullYear(), 2, 0).getDate() === 29 ? "1":"0"
  },
  "o":{
    "description":"ISO 8601 week-numbering year. This has the same value as Y, except that if the ISO week number (W) belongs to the previous or next year, that year is used instead.",
    "example":"Examples: 1999 or 2003",
    "method": (date) => date.getFullYear().toString() //not iso 8601 compliant !
  },
  "Y":{
    "description":"A full numeric representation of a year, 4 digits",
    "example":"Examples: 1999 or 2003",
    "method": (date) => date.getFullYear().toString()
  },
  "y":{
    "description":"A two digit representation of a year",
    "example":"Examples: 99 or 03",
    "method": (date) => date.getFullYear().toString().slice(-2)
  },
  /* time */
  "a":{
    "description":"Lowercase Ante meridiem and Post meridiem",
    "example":"am or pm",
    "method": (date) => date.getHours() >= 12 ? "pm" : "am"
  },
  "A":{
    "description":"Uppercase Ante meridiem and Post meridiem",
    "example":"AM or PM",
    "method": (date) => date.getHours() >= 12 ? "PM" : "AM"
  },
  "B":{
    "description":"Swatch Internet time",
    "example":"000 through 999",
    "method": (date) => {
      //adapted from https://stackoverflow.com/a/57300898/8086209
      let offset = date.toString().split("GMT")[1].split(" ")[0];
      offset = (parseInt(offset.slice(0,3))*3.6e6+parseInt(offset.slice(0,1)+offset.slice(-2))*6e4);
      offset = 3.6e6 - offset;
      parseInt(((+date + offset) % 8.64e7) / 8.64e4).toString();
    }
  },
  "g":{
    "description":"12-hour format of an hour without leading zeros",
    "example":"1 through 12",
    "method": (date) => {
      let hours = date.getHours()%12;
      return hours === 0 ? "12":hours.toString();
    }
  },
  "G":{
    "description":"24-hour format of an hour without leading zeros",
    "example":"0 through 23",
    "method": (date) => date.getHours().toString()
  },
  "h":{
    "description":"12-hour format of an hour with leading zeros",
    "example":"01 through 12",
    "method": (date) => {
      let hours = date.getHours()%12;
      hours = (hours === 0) ? "12":hours.toString();
      return leadingZeros(hours);
    }
  },
  "H":{
    "description":"24-hour format of an hour with leading zeros",
    "example":"00 through 23",
    "method": (date) => leadingZeros(date.getHours())
  },
  "i":{
    "description":"Minutes with leading zeros",
    "example":"00 to 59",
    "method": (date) => leadingZeros(date.getMinutes())
  },
  "s":{
    "description":"Seconds with leading zeros",
    "example":"00 through 59",
    "method": (date) => leadingZeros(date.getSeconds())
  },
  "u":{
    "description":"Microseconds. Note that date() will always generate 000000 since it takes an int parameter, whereas DateTime::format() does support microseconds if DateTime was created with microseconds.",
    "example":"Example: 654321",
    "method": (date) => date.getMilliseconds().toString()+"000" //formatting only, not actual µs-precise time
  },
  "v":{
    "description":"Milliseconds. Same note applies as for u.",
    "example":"Example: 654",
    "method": (date) => date.getMilliseconds().toString()
  },
  /* timezone */
  "e":{
    "description":"Timezone identifier",
    "example":"Examples: UTC, GMT, Atlantic/Azores",
    "method": (date, locale) => date.toLocaleString(locale, { timeZoneName: "long" }) //not exact
  },
  "I":{
    "description":"Whether or not the date is in daylight saving time",
    "example":"1 if Daylight Saving Time, 0 otherwise.",
    "method": () => "" //not implemented
  },
  "O":{
    "description":"Difference to Greenwich time (GMT) without colon between hours and minutes",
    "example":"Example: +0200",
    "method": (date) => date.toString().split("GMT")[1].split(" ")[0]
  },
  "P":{
    "description":"Difference to Greenwich time (GMT) with colon between hours and minutes",
    "example":"Example: +02:00",
    "method": (date) => {
      let offset = date.toString().split("GMT")[1].split(" ")[0];
      return offset.slice(0,3)+":"+offset.slice(-2);
    }
  },
  "p":{
    "description":"The same as P, but returns Z instead of +00:00",
    "example":"Example: +02:00",
    "method": (date) => {
      let offset = date.toString().split("GMT")[1].split(" ")[0];
      return (offset === "+0000") ? "Z":offset.slice(0,3)+":"+offset.slice(-2);
    }
  },
  "T":{
    "description":"Timezone abbreviation, if known; otherwise the GMT offset.",
    "example":"Examples: EST, MDT, +05",
    "method": () => "" //not implemented
  },
  "Z":{
    "description":"Timezone offset in seconds. The offset for timezones west of UTC is always negative, and for those east of UTC is always positive.",
    "example":"-43200 through 50400",
    "method": (date) => {
      let offset = date.toString().split("GMT")[1].split(" ")[0];
      return (parseInt(offset.slice(0,3))*3600+parseInt(offset.slice(0,1)+offset.slice(-2))*60).toString();
    }
  },
  /* full date */
  "c":{
    "description":"ISO 8601 date",
    "example":"2004-02-12T15:19:21+00:00",
    "method": (date) => date.toISOString() //output always in UTC / ending w/ Z
  },
  "r":{
    "description":"» RFC 2822 formatted date",
    "example":"Example: Thu, 21 Dec 2000 16:01:07 +0200",
    "method": (date) => date.toString().replace(/(GMT|(\s*\(.*\)))/g,"")
  },
  "U":{
    "description":"Seconds since the Unix Epoch (January 1 1970 00:00:00 GMT)",
    "example":"See also time()",
    "method": (date) => date.valueOf().toString().slice(0,-3)
  }
};

const formatDate = (isoDate, format, locale) => {
  const date = new Date(isoDate);
  let formatted = "";
  format.split("").forEach((e,i,a) => {
    if (a[i-1] && a[i-1] === "\\") {
      formatted += e;
      return;
    }
    if (e === "\\") {
      return;
    }
    if (phpDateFormats[e]) {
      formatted += phpDateFormats[e].method(date, locale);
      return;
    }
    formatted += e;
  });
  return formatted;
};

const Time = ({ date, className }) => {
  // console.log(date);
  const settings = useStaticQuery(query).wp;
  const timeFormat = settings.allSettings.generalSettingsDateFormat;
  const locale = settings.generalSettings.language.replace("_","-");
  return <time className={ className } dateTime={ date }>{ formatDate(date, timeFormat, locale) }</time>;
};

export const query = graphql`
query Time {
  wp {
    allSettings {
      generalSettingsDateFormat
    }
    generalSettings {
      language
    }
  }
}
`;
export default Time;
